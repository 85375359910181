.CreatePostCard {
  width: 700px;
  height: auto;
  min-height: 430px;
  background-color: #f5f5f5;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 1px rgb(94, 94, 94);
  margin-top: 20px;
  margin-bottom: 10px;
}

.add-post-title-container {
  margin-top: 20px;
  font-size: 40px;
  font-weight: lighter;
  display: flex;
  justify-content: center;
}

.form-container {
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flexbox;
  justify-content: center;
}

.username-field-container {
  width: 200px;
  display: flex;
  text-align: left;
  justify-content: center;
}
